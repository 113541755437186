<template>
    <div>
      <h1>Treasure Marker</h1>
      <div class=box>
        <div class="player">
          <div class="legs"></div>
          <div class="back"></div>
          <div class="glass"></div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'TreasureMarker',
  components: {
  },
  mounted() {
    this.$store.dispatch('victoryMarkerHit');
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

:root {
  --player: #ea1616;
  --player-shadow: #840931;
}

body {
  background: #000000;
  margin: 0;
}

h1 {
  font-size: 20vw;
  color: #038bff;
  text-align: center;
  font-family: "VT323", monospace;
  font-weight: 200;
  position: relative;
  bottom: 0.65em;
}

.box {
  height: 400px;
  width: 100%;
  background-color: green;
  position: absolute;
  bottom: 15vh;
  background-image: radial-gradient(#77dad8 20%, black 95%);
}

.player {
  background-color: var(--player-shadow);
  height: 200px;
  width: 140px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0vw;
  top: 0;
  bottom: 0;
  border: 10px solid #000000;
  border-bottom: none;
  border-radius: 60px 80px 0 0;
  /* z-index: 1; */
}
.player:after {
  content: "";
  position: absolute;
  width: 92%;
  height: 85%;
  background-color: var(--player);
  top: 2.5px;
  left: 6px;
  border-radius: 58% 70% 28% 42% / 28% 56% 88% 89%;
}
.legs {
  height: 50px;
  width: 60px;
  background-color: var(--player-shadow);
  position: absolute;
  bottom: -50px;
  left: -10px;
  border: 10px solid #000000;
  border-top: none;
  border-radius: 0 0 22px 22px;
  /* z-index: 1; */
}
.legs:before {
  content: "";
  height: 45px;
  width: 60px;
  background-color: var(--player-shadow);
  position: absolute;
  right: -90px;
  border: 10px solid #000000;
  border-top: none;
  border-radius: 0 0 22px 22px;
}
.legs:after {
  content: "";
  height: 10px;
  width: 55px;
  background-color: #000000;
  position: absolute;
  top: -10px;
  left: 40px;
  border-radius: 0 0 10px 0;
}
.back {
  height: 120px;
  width: 35px;
  background-color: var(--player);
  border: 10px solid black;
  position: absolute;
  left: -55px;
  top: 65px;
  border-right: none;
  border-radius: 20px 0 0 20px;
}
.back:before {
  content: "";
  position: absolute;
  height: 90px;
  width: 36px;
  background-color: var(--player-shadow);
  bottom: 0;
  left: -1px;
  border-radius: 17px 0 0 8px;
}
.glass {
  height: 75px;
  width: 110px;
  background-color: #225381;
  /* z-index: 2; */
  position: absolute;
  top: 30px;
  left: 40px;
  border-radius: 25px 50px 30px 30px;
  border: 10px solid black;
}
.glass:before {
  content: "";
  position: absolute;
  width: 85%;
  height: 65%;
  background-color: #71d4ec;
  border-radius: 0 28px 3px 30px;
  right: 0;
  top: 0;
}
.glass:after {
  content: "";
  position: absolute;
  width: 45%;
  height: 22%;
  background-color: #f7f7f7;
  left: 39px;
  top: 5px;
  border-radius: 10px;
  transform: rotate(6deg);
}

.box {
  background: radial-gradient(#77dad8 20%, black 95%);
  opacity: 0;
  animation: fadeIn ease 10s;
  animation-fill-mode: forwards;
}

h1 {
  font-size: 20vw;
  color: #038bff;
  text-align: center;
  font-family: "VT323", monospace;
  font-weight: 200;
  position: relative;
  /* z-index: 2; */
  bottom: 0.65em;
  animation: moving 6s;
  animation-fill-mode: forwards;
}

@keyframes moving {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(50px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 8rem;
    bottom: 0.15em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  h1 {
    font-size: 10rem;
    bottom: 0.15em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 20vw;
    bottom: 0.65em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  h1 {
    font-size: 15vw;
    bottom: 0.55em;
  }
}

</style>
